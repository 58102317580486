/**
 * 加载和浏览器相关的资源，例如 CSS 样式
 */
const React = require("react")
const ReactDOM = require("react-dom")

require("bootstrap/dist/css/bootstrap.min.css")
require("./src/css/reset.css")
require("./src/css/main.css")

// Expose React and ReactDOM as globals for console playground
window.React = React
window.ReactDOM = ReactDOM

// A stub function is needed because gatsby won't load this file otherwise
// (https://github.com/gatsbyjs/gatsby/issues/6759)
exports.onClientEntry = () => {}
